/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { StaticQuery, graphql } from "gatsby"
import styled from "styled-components"

import Header from "./header"
import "./layout.css"
// Hiding archive as not sure how to use it so it looks good
// import Archive from "./archive"
import Footer from "./footer"

//Query Goes here
const SITE_QUERY = graphql`
  query SiteQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`

//Styling goes here
const Page = styled.div`
  display: grid;
  grid-template-columns: 3px 1fr 3px;
  @media only screen and (min-width: 500px) {
    grid-template-columns: 2fr 12fr 2fr; /*2fr is for the sidebar, which will stack in tablet and mobile view*/
  }
  grid-template-rows: auto auto 50px;
  grid-gap: 10px;
  justify-items: center;
`

const Content = styled.div`
  padding: 0 0;
  grid-column: 2 / -2;
  grid-row: 2 / -2;
`

// Render goes here
const Layout = ({ children }) => (
  <StaticQuery
    query={SITE_QUERY}
    render={data => (
      <Page>
        <Helmet>title={data.site.siteMetadata.title}</Helmet>
        <Header siteTitle={data.site.siteMetadata.title} />
        <Content>
          {children}
          {/* <Archive /> */}
        </Content>
        <Footer siteTitle={data.site.siteMetadata.title}></Footer>
      </Page>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
