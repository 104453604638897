import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import tw from "tailwind.macro"

// import buidlLogo from "../images/final_logo_white.svg"

const FooterWrapper = styled.div`
  ${tw`mb-7 bg-gray-300`};
  img {
    ${tw`mb-0`};
  }
  grid-column: 1 / -1;
  grid-row: 3/4;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`
// const FooterContainer = styled.div`
//   ${tw`max-w-lg mx-4 p-4`};
//   h1 {
//     ${tw`mb-0 text-white`};
//   }
// `

const StyledLink = styled(Link)`
  ${tw`text-gray-700 no-underline`};
`

// const Image = styled.img`
//   ${tw`inline-block m-0 m-0 mr-2 w-6  `};
// `

const Footer = ({ siteTitle }) => (
  <FooterWrapper>
    {/* <FooterContainer> */}
    {/* <h1>
        <StyledLink to="/">
          <Image src={buidlLogo} alt="Buidl logo" />
          {siteTitle}
        </StyledLink>
      </h1> */}
    {/* </FooterContainer> */}
    <div>
      <StyledLink to="/">
        © {new Date().getFullYear()}, {siteTitle}
      </StyledLink>
    </div>
  </FooterWrapper>
)

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: `theBUIDL`,
}

export default Footer
