import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import tw from "tailwind.macro"
import Headroom from "react-headroom"
import buidlLogo from "../images/final_logo_orange.svg"

// styled components

const HeaderWrapper = styled.div`
  grid-column: 1/-1;
  grid-row: 1;
  display: flex;
  height: auto;
  align-items: center;
  justify-content: center;
  ${tw`bg-gray-300`};
`
const HeaderContainer = styled.div`
  display: flex;
  min-width: 250px;
  ${tw`mx-4 pt-2`};
  h2 {
    ${tw`mb-0 text-orange-500 text-4xl`};
  }
  h3 {
    ${tw`mb-0 my-4 font-normal`};
  }
  align-items: center;
  @media screen and (max-width: 550px) {
    flex-flow: column;
    h3 {
      ${tw`mx-auto`};
    }
  }

  @media screen and (min-width: 550px) and (max-width: 850px) {
    justify-content: space-between;
    flex-grow: 1;
    h2 {
      ${tw`text-4xl`};
    }
    h3 {
      ${tw`mx-auto mb-4 text-md`};
    }
  }
  @media screen and (min-width: 850px) {
    max-width: 1240px;
    justify-content: space-between;
    flex-grow: 1;
    h2 {
      ${tw`text-4xl`};
    }
    h3 {
      ${tw`mx-auto mb-4 text-lg`};
    }
  }
`
const LogoDiv = styled.div`
  display: flex;
  display: -webkit-box;
  display: -ms-flexbox;
`

const StyledLink = styled(Link)`
  ${tw`text-gray-700 no-underline`};
`

const LogoLink = styled(Link)`
  ${tw`no-underline text-orange-500`};
`

const Image = styled.img`
  ${tw`inline-block mr-2 w-8  `};
`

const Nav = styled.nav`
  display: flex;
  flex-direction: row;
  padding: 10px;
  h3 {
    margin: 10px;
  }
`

// header component
//! placeholders are already in for Tags, companies and podcast links

const Header = ({ siteTitle }) => (
  <Headroom disableInlineStyles>
    <HeaderWrapper>
      <HeaderContainer>
        <LogoDiv>
          <Image src={buidlLogo} alt="Buidl logo" />
          <h2>
            <LogoLink to="/">{siteTitle}</LogoLink>
          </h2>
        </LogoDiv>
        <Nav>
          <h3>
            <StyledLink to="/">Stories</StyledLink>
          </h3>
          <h3>
            <StyledLink to="/about/">About</StyledLink>
          </h3>
          <h3>
            <StyledLink to="/contact/">Contact</StyledLink>
          </h3>

          {/*           
          <h3>
            <StyledLink to="/">Tags</StyledLink>
          </h3>
          <h3>
            <StyledLink to="/">Companies</StyledLink>
          </h3>
          <h3>
            <StyledLink to="/">Podcast</StyledLink>
          </h3> */}
        </Nav>
      </HeaderContainer>
    </HeaderWrapper>
  </Headroom>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: `theBUIDL`,
}

export default Header
