import React from "react"
import Layout from "../components/layout"
import styled from "styled-components"

const ContactDiv = styled.div`
  text-align: center;
`

const OutLink = styled.a`
  color: hsl(22, 78%, 55%);
  text-decoration: none;
  border-bottom: 1px solid black;
`

const BorderDiv = styled.div`
  border-bottom: 1px solid black;
  margin: 15px;
`

const Contact = () => (
  <Layout>
    <ContactDiv>
      <h1>Contact Us</h1>
      <BorderDiv>
        <OutLink href="https://forms.gle/ucPS1RzP41CVgjKCA">
          <h3>Request an Article</h3>
        </OutLink>
        <p>
          Please fill this form if you will like to request an article or give a
          tip
        </p>
      </BorderDiv>
      <BorderDiv>
        <OutLink href="https://forms.gle/8A75PK5jiLFLu4QF8">
          <h3>Sponsor Us</h3>
        </OutLink>
        <p>Please fill this form if you are interested in sponsoring us</p>
      </BorderDiv>
    </ContactDiv>
  </Layout>
)

export default Contact
